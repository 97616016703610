import { PostgrestError } from '@supabase/supabase-js';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../utils/supabase';
interface Radio {
  id: string;
  name: string;
  logo: string;
  slug: string;
  listenURL: string;
  type: string;
}

const Details: React.FC = () => {
  let { id } = useParams();
  const [radio, setRadio] = useState<Radio[]>([]);
  const [protocole, setProtocole] = useState<string>();
  const [url, setUrl] = useState<string>();
  const [mount, setMount] = useState<string>();

  useEffect(() => {
    document.title = "Accueil - Listen My Stream";
    if (id){
      getRadio(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  async function getRadio(id:string) {
    const { data, error }: { data: Radio[] | null, error: PostgrestError | null } = await supabase.from("radios").select().eq('slug', id);
    if (error) {
      console.log(error.message);
      return;
    }
    if (data) {
      setRadio(data);
      setProtocole("https")
      setUrl(data[0].listenURL.split('/')[2])
      setMount(data[0].listenURL.split('/')[3])

    } else {
      setRadio([]);
    }
  }
  return (
    <div className="flex items-center justify-center h-full">
      {radio.map((radio) => (
          <div key={radio.id} className="flex-shrink-0 w-48 mr-4">
            <h1 className="text-4xl">{radio.name}</h1>
            <h2 className='text-xl'>{radio.listenURL}</h2>
            <h2 className='text-xl'>{radio.type}</h2>
            <h2 className='text-xl'>Protocole : {protocole}</h2>
            <h2 className='text-xl'>Serveur : {url}</h2>
            <h2 className='text-xl'>Mount : {mount}</h2>
          </div>
        ))}
    </div>
  );
}

export default Details;
