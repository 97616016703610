import React from 'react';

const Register: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <h1 className="text-4xl">Register</h1>
    </div>
  );
}

export default Register;
