import React, { useEffect, useState } from 'react';
import RadioList from '../components/RadioList';
import { PostgrestError } from "@supabase/supabase-js";
import {supabase} from "../utils/supabase"
// const radios = [
//   { id: '1', name: 'Radio Une', logo: 'https://via.placeholder.com/150' },
//   { id: '2', name: 'Radio Deux', logo: 'https://via.placeholder.com/150' },
//   { id: '3', name: 'Radio Trois', logo: 'https://via.placeholder.com/150' },
//   // Ajoute d'autres radios ici
// ];
interface Radio {
  id: string;
  name: string;
  logo: string;
  slug: string;
  listenURL: string;
  type: string;
}

//const supabase = createClient("https://ifqiswqzyetsoyncnlfb.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlmcWlzd3F6eWV0c295bmNubGZiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTk4NzI2NDksImV4cCI6MjAzNTQ0ODY0OX0.CHFB6aHkbYFrT9Pypj6iifUK10gVsX98BTvDd7d-wSE");

const Home: React.FC = () => {
  const [radios, setRadios] = useState<Radio[]>([]);
  const [, setError] = useState<string | null>(null);
  const [most, setMost] = useState<Radio[]>([]);
  const [promoted, setPromoted] = useState<Radio[]>([]);

  useEffect(() => {
    document.title = "Accueil - Listen My Stream";
    getRadios();
    getMostListened();
    getPromoted();
  }, [])
  
  async function getRadios() {
    const { data, error }: { data: Radio[] | null, error: PostgrestError | null } = await supabase.from("radios").select().order('created_at').limit(7);
    if (error) {
      setError(error.message);
      return;
    }
    if (data) {
      setRadios(data);
    } else {
      setRadios([]);
    }
  }
  async function getMostListened() {
    const { data, error }: { data: Radio[] | null, error: PostgrestError | null } = await supabase.from("radios").select().order('listenCount', { ascending: false});
    if (error) {
      setError(error.message);
      return;
    }
    if (data) {
      setMost(data);
    } else {
      setMost([]);
    }
  }
  async function getPromoted() {
    const { data, error }: { data: Radio[] | null, error: PostgrestError | null } = await supabase.from("radios").select().eq('isPromoted',true).order('listenCount', { ascending: false});
    if (error) {
      setError(error.message);
      return;
    }
    if (data) {
      setPromoted(data);
    } else {
      setPromoted([]);
    }
  }
  return (
    <div className="container mx-auto px-4">
      <RadioList header="Radios les plus écoutées" radios={most} />
      <RadioList header="Radios en vedette" radios={promoted} />
      <RadioList header="Nouvelles radios" radios={radios} />
    </div>
  );
}

export default Home;
