import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PlayerContextType {
  currentTrack: string | null;
  currentArtist: string | null;
  currentStreamUrl: string | null;
  type: string | null;
  isPlaying: boolean;
  playTrack: (track: string, artist: string, streamUrl: string, type: string) => void;
  stopTrack: () => void;
}

const PlayerContext = createContext<PlayerContextType | undefined>(undefined);

export const PlayerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentTrack, setCurrentTrack] = useState<string | null>(null);
  const [currentArtist, setCurrentArtist] = useState<string | null>(null);
  const [currentStreamUrl, setCurrentStreamUrl] = useState<string | null>(null);
  const [type, setType] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const playTrack = (track: string, artist: string, streamUrl: string, type: string) => {
    setCurrentTrack(track);
    setCurrentArtist(artist);
    setCurrentStreamUrl(streamUrl);
    setIsPlaying(true);
    setType(type)
  };

  const stopTrack = () => {
    setIsPlaying(false);
  };

  return (
    <PlayerContext.Provider value={{ currentTrack, currentArtist, currentStreamUrl, isPlaying, playTrack, stopTrack, type }}>
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayer = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }
  return context;
};
