import React, { useState, useEffect, useRef } from 'react';
import { usePlayer } from './PlayerContext';
import axios from 'axios';

const Player: React.FC = () => {
  const {currentStreamUrl, isPlaying, playTrack, stopTrack, type } = usePlayer();
  const audioRef = useRef<HTMLAudioElement>(null);
  const [volume, setVolume] = useState(50);
  const [title, setTitle] = useState<string | null>(null);
  const [artist, setArtist] = useState<string | null>(null);
  const [coverUrl, setCoverUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchTrackInfo = async (currentStreamUrl : string | null, type: string | null) => {
      try {
        if(currentStreamUrl !== '' && currentStreamUrl !== null && type !== '' && type !== null) {
            const splitedUrl = currentStreamUrl.split("/")
            const response = await axios.get('https://alex.streamapps.fr/onair/https%3A%2F%2F'+encodeURI(splitedUrl[2])+'/'+type); // Remplacez 'YOUR_API_ENDPOINT' par l'URL de votre API
            const { title, artist, cover } = response.data.metadata;
            setTitle(title);
            setArtist(artist);
            setCoverUrl(cover);
        } else  {
            setTitle("Sélectionnez une radio");
            setArtist("Afin de pouvoir jouer son contenu");
            setCoverUrl("https://via.placeholder.com/50");
        }
        
      } catch (error) {
        console.error('Error fetching track info:', error);
      }
    };

    fetchTrackInfo(currentStreamUrl, type); // Appel initial

    const interval = setInterval(() => {
      fetchTrackInfo(currentStreamUrl, type);
    }, 10000); // Appelle l'API toutes les 10 secondes

    return () => clearInterval(interval); // Nettoie l'intervalle lorsque le composant est démonté
  }, [currentStreamUrl, type]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.src = currentStreamUrl || '';
        audioRef.current.play();
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Réinitialise l'audio au début
      }
    }
  }, [isPlaying, currentStreamUrl]);

  const togglePlayPause = () => {
    if (isPlaying) {
      stopTrack();
    } else {
      playTrack(title || '', artist || '', currentStreamUrl || '', type || '');
      setArtist("Chargement")
      setTitle('De la radio en cours')
    }
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 p-4 bg-gray-800 text-white z-50">
      <audio ref={audioRef} />
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img src={coverUrl || 'https://via.placeholder.com/50'} alt="Album Art" className="mr-4 w-[50px]" />
          <div>
            <p className="text-lg">{title || 'Titre de la chanson'}</p>
            <p className="text-sm text-gray-400">{artist || 'Artiste'}</p>
          </div>
        </div>
        <div className="flex items-center">
          <button onClick={togglePlayPause} className="mr-4">
            {isPlaying ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 5.25v13.5M17.25 5.25v13.5" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
              </svg>
            )}
          </button>
          <input
            type="range"
            min="0"
            max="100"
            value={volume}
            onChange={(e) => {
              if (audioRef.current) {
                audioRef.current.volume = parseInt(e.target.value) / 100;
              }
              setVolume(parseInt(e.target.value));
            }}
            className="w-24 hidden lg:block"
          />
        </div>
      </div>
    </div>
  );
};

export default Player;
