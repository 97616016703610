import React from 'react';
import { usePlayer } from './PlayerContext';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

interface RadioCardProps {
  id: string;
  name: string;
  logo: string;
  streamUrl: string;
  type: string;
}

const RadioCard: React.FC<RadioCardProps> = ({ id, name, logo, streamUrl, type }) => {
  const { playTrack } = usePlayer();
  const navigate = useNavigate();

  const handlePlay = () => {
    playTrack(name, '', streamUrl, type);
    Swal.fire({
        toast: true,
        icon: 'success',
        title: 'Vous écoutez '+name,
        animation: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
  };

  const goToDetails = () => {
    navigate(`/details/${id}`);
    
  };

  return (
    <div className="relative w-48 h-48 bg-gray-100 rounded-lg overflow-hidden shadow-lg m-2 cursor-pointer" onClick={goToDetails}>
      <div >
      <img src={logo} alt={name} className="w-full h-full object-cover" />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300">
          <button onClick={handlePlay} className="text-white">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
            </svg>
          </button>
        </div>
      </div>
      <div className="absolute bottom-0 w-full bg-black bg-opacity-75 text-white text-center py-2">
        {name}
      </div>
    </div>
  );
};

export default RadioCard;
