import React from 'react';

const Login: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <h1 className="text-4xl">Login</h1>
    </div>
  );
}

export default Login;
