import React from 'react';
import RadioCard from './RadioCard';

interface Radio {
    id: string;
    name: string;
    logo: string;
    slug: string;
    listenURL: string;
    type: string;
  }

interface RadioListProps {
  header: string;
  radios: Radio[];
}

const RadioList: React.FC<RadioListProps> = ({ header, radios }) => {
  return (
    <div className="my-8 overflow-x-auto">
      <h2 className="text-2xl font-bold mb-4">{header}</h2>
      <div className="flex flex-nowrap">
        {radios.map((radio) => (
          <div key={radio.id} className="flex-shrink-0">
            <RadioCard key={radio.id} id={radio.slug} name={radio.name} logo={radio.logo} streamUrl={radio.listenURL} type={radio.type} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default RadioList;
