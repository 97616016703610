import React from 'react';
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
  return (
    <nav className="bg-gradient-to-r from-violet-500 to-fuchsia-500 p-4 text-white">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-lg font-bold">
            <img src="https://listenmystream.com/assets/img/logo-w2.png" alt="Logo ListenMyStream"  className='w-20 h-auto'/>
        </div>
        <div className="space-x-4">
          <Link to="/" className="hover:text-gray-400">Accueil</Link>
          <Link to="/login" className="hover:text-gray-400">Connexion</Link>
          <Link to="/register" className="hover:text-gray-400">Inscription</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
